import { Grid } from '@mui/material'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Blog } from '../../support/contentful/content-types'
import { BlogItem } from './BlogItem'
import { FetchProvidersResponse, fetchProviders, ProviderInfo } from '../../services/api/get-providers'
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient'
import { getTags, Tag } from '../../services/api/get-tags'

type BlogListProps = {
  items: Blog[]
}

/**
 * Renders a list of blog items with their corresponding providers.
 *
 * @param {BlogListProps} items - The array of blog items to render.
 * @return {JSX.Element} The rendered list of blog items.
 */
export const BlogList: FunctionComponent<BlogListProps> = ({ items }) => {
  const apolloClient = useApolloClient()
  const [providers, setProviders] = useState<FetchProvidersResponse>()
  const [tags, setTags] = useState<Tag[]>([])
  const [loadingTags, setLoadingTags] = useState<boolean>(true)

  useEffect(() => {
    const fetchAndSetTags = async () => {
      try {
        const tags = await getTags(apolloClient)
        setTags(tags)
      } catch (error) {
        console.error('Error fetching tags:', error)
      } finally {
        setLoadingTags(false)
      }
    }
    fetchAndSetTags()
  }, [apolloClient])

  useEffect(() => {
    const orglist = items.map((item) => {
      const orgId = Number(item.fields.organisationId)
      return isNaN(orgId) ? 0 : orgId
    })

    const fetchAndSetProviders = async () => {
      console.log('Fetching providers', orglist)
      const response = await fetchProviders(apolloClient, { providerIds: orglist })
      console.log('Fetched providers', response)
      if (!response) return
      setProviders(response)
    }

    fetchAndSetProviders()
  }, [apolloClient, items])

  const blogItemsWithProviders = useMemo<{ blog: Blog; provider: ProviderInfo | null }[]>(() => {
    if (!providers) return []

    return items.map((item) => {
      const organisationId = Number(item.fields.organisationId)
      const matchingProvider = providers.getProviders.find((provider) => provider.id === organisationId)

      return {
        blog: item,
        provider: matchingProvider || null
      }
    })
  }, [items, providers])

  if (loadingTags) {
    return <div>Loading tags...</div>
  }

  return (
    <Grid container spacing={4}>
      {blogItemsWithProviders.map((blogItemWithProvider) => (
        <BlogItem
          key={blogItemWithProvider.blog.sys.id}
          blog={blogItemWithProvider.blog}
          provider={blogItemWithProvider.provider}
          allTags={tags}
        />
      ))}
    </Grid>
  )
}
