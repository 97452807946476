import { Box as Container, Grid, Paper, Typography, Box, Divider } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BlogImage } from '../../components/Blog/BlogImage'
import { Blog } from '../../support/contentful/content-types'
import getFormattedDate from '../../support/date/getFormattedDate'
import TagStack from '../Common/TagStack'
import CountryFlag from '../Common/CountryFlag'
import { ProviderInfo } from '../../services/api/get-providers'
import { SegmentBadge } from '../Common/SegmentBadge'
import LimitedTypography from '../Typography/LimitedTypography'
import { Tag } from '../../services/api/get-tags'

const ItemGrid = styled(Grid)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`

/**
 * The props for the BlogItem component.
 *
 * @property {Blog} item - The blog item to render.
 */
type BlogItemProps = {
  blog: Blog
  provider: ProviderInfo | null
  allTags: Tag[]
}
export const BlogItem: React.FC<BlogItemProps> = ({ blog: blogPost, provider, allTags }: BlogItemProps) => {
  const navigate = useNavigate()
  const formattedDate = useMemo(
    () => getFormattedDate(blogPost.fields.originalPostDate),
    [blogPost.fields.originalPostDate]
  )
  const tags = useMemo(
    () =>
      blogPost.metadata.tags?.map(({ sys }) => {
        const tag = allTags.find((t) => t.id === sys.id)
        return {
          text: tag?.name || '',
          onClick: () => {}
        }
      }) ?? [],
    [blogPost.metadata.tags, allTags]
  )
  const imageUrl = provider?.image ?? ''

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={blogPost.sys.id}>
      <Paper data-cy={`blogitem-${blogPost.sys.id}`} style={{ height: '100%', overflow: 'hidden' }}>
        <ItemGrid
          id="itemGrid"
          style={{ height: '100%', justifyContent: 'space-between' }}
          container
          direction="column"
          justifyContent="space-between"
          onClick={() => navigate(`/insights/${blogPost.fields.path}`)}
        >
          <Grid item>
            <Container paddingX={2} paddingTop={2}>
              <LimitedTypography variant="h3">{blogPost.fields.title}</LimitedTypography>
            </Container>
          </Grid>

          <Grid container direction="row" spacing={2} alignItems="center">
            {' '}
            <Grid item>
              <Box style={{ width: '60px', height: '60px', display: 'block', position: 'relative', marginLeft: '8px' }}>
                <BlogImage asset={imageUrl} height="60px" width="60px" rounded />
              </Box>
            </Grid>
            <Grid item xs>
              <Container
                marginLeft={1}
                marginRight={1}
                style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}
              >
                <LimitedTypography variant="body1">{blogPost.fields.subtitle}</LimitedTypography>
              </Container>
            </Grid>
          </Grid>

          <Box style={{ marginTop: 'auto', paddingBottom: '10px' }}>
            <Divider />
            <Container display="flex" justifyContent="space-between" alignItems="flex-end" padding={0}>
              <Box display="flex" alignItems="center" style={{ flexShrink: 0 }}>
                <CountryFlag countryCode={blogPost.fields.organisationRegion} />
                {blogPost && <SegmentBadge segment={blogPost.fields.segmentId} />}
              </Box>
              <Box>
                <Typography variant="caption">{formattedDate}</Typography>
                <TagStack tags={tags} />
              </Box>
            </Container>
          </Box>
        </ItemGrid>
      </Paper>
    </Grid>
  )
}
