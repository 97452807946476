/**
 * Enum representing the available feature flags within the application.
 *
 * This enum is used to define the keys for feature flags that control
 * the availability of different features in the application based on
 * user or server-side configurations.
 *
 * ## Usage
 *
 * Import the `FeatureFlag` enum to access feature flags in a type-safe manner.
 * For example, use `FeatureFlag.ShowLibrary` to reference the 'show-library' feature flag.
 *
 * ```typescript
 * import { FeatureFlag } from './featureFlags';
 *
 * const { value: showLibrary } = useFlag(FeatureFlag.ShowLibrary, false);
 * ```
 *
 * ## Adding New Feature Flags
 *
 * To add a new feature flag:
 * 1. Define a new entry in this enum with a descriptive name.
 * 2. Set its value to the corresponding string used in the flag management system.
 *
 * Example:
 * ```typescript
 * export enum FeatureFlag {
 *   ShowLibrary = 'show-library',
 *   NewFeature = 'new-feature' // Add your new feature flag here
 * }
 * ```
 *
 * ## Notes
 *
 * - Ensure that the values of the enum match the feature flag keys expected by your flag management system.
 * - Keep the enum updated as new feature flags are added or removed.
 */
export enum FeatureFlag {
  /** Feature flag to control the visibility of the Library menu item */
  ShowLibrary = 'show-library'

  // Add more feature flags here as needed
}
