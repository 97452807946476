import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../components/Router/ProtectedRoute'
import React from 'react'

// Lazy load the components
const Resources = React.lazy(() => import('../pages/Resources').then((module) => ({ default: module.Resources })))
const Download = React.lazy(() =>
  import('../pages/Resources/Download').then((module) => ({ default: module.Download }))
)
const Unauthorised = React.lazy(() =>
  import('../pages/Resources/Unauthorised').then((module) => ({ default: module.Unauthorised }))
)

export const ResourceContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={'/resources'}
          element={
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/resources/unauthorised'}
          element={
            <ProtectedRoute>
              <Unauthorised />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/resources/:resourceId'}
          element={
            <ProtectedRoute>
              <Download />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/reports/:resourceId'}
          element={
            <ProtectedRoute>
              <Download />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<></>} />
      </Routes>
    </Suspense>
  )
}
