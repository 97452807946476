import { ThemeProvider } from '@mui/material/styles'
import { Auth } from 'aws-amplify'
import { FunctionComponent, useMemo } from 'react'
import ReactDOM from 'react-dom/client' // Import the new API
import App from './App'
import { EnvironmentProvider } from './contexts/EnvironmentContext'
import { IdentityProvider } from './contexts/IdentityContext'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { environment } from './support/environment'
import { factory } from './theme'

/**
 * Configure Amplify with passed ENV variables,
 * This is done here because we need them present for auth checks early on.
 */
Auth.configure({
  Auth: {
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    region: environment.cognitoRegion,
    mandatorySignIn: false
  }
})

type BootstrapProps = {
  children: JSX.Element
}

/**
 * Bootstrap Component for memorizing the theme.
 * Without memorizing it, it can cause some unintended side effects in production.
 */
const Bootstrap: FunctionComponent<BootstrapProps> = ({ children }) => {
  const theme = useMemo(() => factory(environment.branding.name), [])

  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeProvider>
  )
}

/**
 * The main app entry point for React.
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement) // Create a root
root.render(
  <Bootstrap>
    <EnvironmentProvider environment={environment}>
      <IdentityProvider>
        <App />
      </IdentityProvider>
    </EnvironmentProvider>
  </Bootstrap>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
