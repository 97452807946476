import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../components/Router/ProtectedRoute'
import { LibraryCriteriaProvider } from '../contexts/Search/LibraryCriteriaProvider'
import { LibraryResultsProvider } from '../contexts/Search/LibraryResultsProvider'
import { LibraryQueryProvider } from '../contexts/Search/LibraryQueryProvider'
import React from 'react'

// Lazy load the components
const Library = React.lazy(() => import('../pages/Library').then((module) => ({ default: module.Library })))
const Unauthorised = React.lazy(() =>
  import('../pages/Resources/Unauthorised').then((module) => ({ default: module.Unauthorised }))
)

export const LibraryContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={'/library'}
          element={
            <ProtectedRoute>
              <LibraryQueryProvider>
                <LibraryCriteriaProvider>
                  <LibraryResultsProvider>
                    <Library />
                  </LibraryResultsProvider>
                </LibraryCriteriaProvider>
              </LibraryQueryProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={'/library/unauthorised'}
          element={
            <ProtectedRoute>
              <Unauthorised />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<></>} />
      </Routes>
    </Suspense>
  )
}
