import { alpha, createTheme, Theme, DeprecatedThemeOptions, adaptV4Theme } from '@mui/material/styles';
import { getBrands } from './brands'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    navigation: Palette['primary']
  }
  interface PaletteOptions {
    navigation: PaletteOptions['primary']
  }
}

const font = "'Inter', sans-serif"
const buttonShadow = '0 1px 3px 0 rgba(65,69,88,0.2);'

let theme: Theme | undefined = undefined

/**
 * Material UI base them with overrides.
 */
const createIskyTheme = (overrides: DeprecatedThemeOptions): Theme => {
  return createTheme(adaptV4Theme({
    props: {
      MuiButtonBase: {
        disableRipple: true
      },
      MuiButtonGroup: {
        disableRipple: true
      }
    },

    overrides: {
      MuiPaper: {
        rounded: {
          borderRadius: '6px'
        },
        elevation1: {
          boxShadow: '0 1px 3px 0 rgba(65,69,88,0.2)'
        }
      },
      MuiSwitch: {
        root: {
          padding: '0'
        },
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#ccc'
        },
        colorSecondary: {
          '&$checked': {
            // Controls checked color for the thumb
            color: '#f2ff00'
          }
        },
        thumb: {
          border: '1px solid #D9DBE0',
          boxSizing: 'border-box',
          transformStyle: 'flat'
        },
        track: {
          // Controls default (unchecked) color for the track
          boxSizing: 'border-box',
          height: 15,
          opacity: 0.2,
          backgroundColor: '#fff',
          '$checked$checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: '#fff'
          }
        }
      },
      MuiInput: {
        root: {
          color: '#647386',
          borderRadius: '6px',
          border: '1px solid #D9DBE0'
        }
      },
      MuiButton: {
        root: {
          borderRadius: '6px',
          textTransform: 'none',
          boxShadow: buttonShadow,
          '&$disabled': {
            cursor: 'not-allowed'
          }
        },
        contained: {
          boxShadow: buttonShadow
        }
      }
    },
    typography: {
      fontFamily: font,
      h1: {
        fontSize: '30px',
        color: '#001433',
        fontWeight: 700
      },
      h2: {
        fontSize: '24px',
        color: '#001433',
        fontWeight: 700
      },
      h3: {
        fontSize: '18px',
        color: '#001433',
        fontWeight: 700
      },
      h4: {
        fontSize: '14px',
        color: '#001433',
        fontWeight: 700
      },
      body1: {
        fontSize: '14px',
        lineHeight: '1.5',
        color: '#647386'
      },
      body2: {
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#647386'
      }
    },
    ...overrides
  }));
}

export const overrides = (brand?: string): DeprecatedThemeOptions => {
  const config = getBrands(brand || '')

  return {
    palette: {
      action: {
        disabledBackground: alpha(config.colors.secondary, 0.5),
        disabled: alpha('#fff', 0.8)
      },
      primary: {
        main: config.colors.main
      },
      secondary: {
        main: config.colors.secondary
      },
      navigation: config.colors.navigation
    }
  }
}

export const factory = (brand?: string) => {
  if (theme === undefined) {
    theme = createIskyTheme(overrides(brand))
  }

  return theme
}
