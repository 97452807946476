// RadarCriteriaProvider.tsx
import React from 'react'
import { CriteriaProvider } from './Criteria'

type RadarCriteriaProviderProps = {
  children?: React.ReactNode
}
export const RadarCriteriaProvider: React.FC<RadarCriteriaProviderProps> = ({ children }) => {
  return <CriteriaProvider criteriaType="RADAR">{children}</CriteriaProvider>
}
