// RadarResultsProvider.tsx
import React from 'react'
import { ResultsProvider } from './Results' // Import the base context

type RadarResultsProviderProps = {
  children?: React.ReactNode
}
export const RadarResultsProvider: React.FC<RadarResultsProviderProps> = ({ children }) => {
  return <ResultsProvider resultsType="RADAR">{children}</ResultsProvider>
}
