// LibraryResultsProvider.tsx
import React from 'react'
import { ResultsProvider } from './Results' // Import the base context

type LibraryResultsProviderProps = {
  children?: React.ReactNode
}

export const LibraryResultsProvider: React.FC<LibraryResultsProviderProps> = ({ children }) => {
  return <ResultsProvider resultsType="LIBRARY">{children}</ResultsProvider>
}
