import { Box, Container, Grid, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { useIdentityContext } from '../../contexts/IdentityContext'
import HeaderLogo from './HeaderLogo/HeaderLogo'
import Menu from './Menu/Menu'
import { OpenFeature } from '@openfeature/react-sdk'

export type HeaderProps = {
  brand?: string
}

export const Header = (props: HeaderProps) => {
  const theme = useTheme()
  const { isLoggedIn, session } = useIdentityContext()
  session?.attributes?.['custom:Company'] &&
    OpenFeature.setContext({ company: session.attributes['custom:Company'].toLowerCase() })

  return (
    <Box display={'flex'} bgcolor={theme.palette.navigation.main}>
      <Container maxWidth={'lg'}>
        {isLoggedIn ? (
          <Grid container>
            <Grid item xs={1}>
              <Link to={'/dashboard'} title={'Radar'}>
                <HeaderLogo type={'app'} />
              </Link>
            </Grid>
            <Grid item xs={11}>
              <Menu />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Box paddingY={2}>
                <HeaderLogo type={'default'} />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  )
}

export default Header
