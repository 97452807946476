/**
 * @file RadarQueryProvider.tsx
 * @description This file provides a context wrapper specifically for managing the query state of the Radar section.
 * It ensures that the Radar query state is separated from other parts of the application, such as the Library section,
 * allowing distinct query state management for different sections.
 */

import { FunctionComponent, ReactNode } from 'react'
import { QueryProvider } from './Query' // Import the base context

/**
 * @typedef {Object} RadarQueryProviderProps
 * @property {ReactNode} [children] - The child components to be wrapped by the RadarQueryProvider.
 */
type RadarQueryProviderProps = {
  children?: ReactNode
}

/**
 * RadarQueryProvider is a wrapper component that provides a context for managing query state
 * specific to the Radar section of the application. By passing the `queryType` as "RADAR",
 * this ensures that the query state is isolated from other sections like Library.
 *
 * @component
 * @param {RadarQueryProviderProps} props - The props for the RadarQueryProvider component.
 * @returns {JSX.Element} The component that wraps its children in a Radar-specific query context.
 */
export const RadarQueryProvider: FunctionComponent<RadarQueryProviderProps> = ({ children }) => {
  return <QueryProvider queryType="RADAR">{children}</QueryProvider>
}
