import { ApolloProvider } from '@apollo/client'
import AppRoutes from '../../AppRoutes'
import { AdvancedJourneyProvider } from '../../contexts/AdvancedJourneyContext'
import { ChannelProvider } from '../../contexts/ChannelContext'
import { CompareChannelProvider } from '../../contexts/CompareChannelContext'
import { ComparisonProvider } from '../../contexts/ComparisonContext'
import { useIdentityContext } from '../../contexts/IdentityContext'
import { PrioritisationOverlayProvider } from '../../contexts/PrioritisationOverlayContext'
import { createApolloClient } from '../../support/graphql/client'
import Layout from './Layout'

/**
 * This component wraps the entire application in the necessary context
 * providers, as well as the apollo client.
 *
 * It checks if the user is authenticated, and if the apollo client can be
 * created. If either of these conditions are not met, it returns null.
 *
 * If the user is authenticated, it renders the entire application inside
 * of the context providers.
 *
 * @returns The application wrapped in context providers, or null.
 */
export const Authenticated = () => {
  const { session } = useIdentityContext()

  const client = createApolloClient()

  if (!session) return null
  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <ComparisonProvider>
        <PrioritisationOverlayProvider>
          <AdvancedJourneyProvider>
            <ChannelProvider>
              <CompareChannelProvider>
                <Layout>
                  <AppRoutes />
                </Layout>
              </CompareChannelProvider>
            </ChannelProvider>
          </AdvancedJourneyProvider>
        </PrioritisationOverlayProvider>
      </ComparisonProvider>
    </ApolloProvider>
  )
}
