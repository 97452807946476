import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import React from 'react'

// Lazy load all the pages
const Login = React.lazy(() => import('../pages/Auth/Login'))
const Register = React.lazy(() => import('../pages/Auth/Register'))
const Confirm = React.lazy(() => import('../pages/Auth/Confirm'))
const ForgotPassword = React.lazy(() => import('../pages/Auth/ForgotPassword'))
const ChangePassword = React.lazy(() => import('../pages/Auth/ChangePassword'))

export const AuthContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={'/login'} element={<Login />} />
        <Route path={'/register'} element={<Register />} />
        <Route path={'/confirm'} element={<Confirm />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/forgot-password/confirm'} element={<ChangePassword />} />
        <Route path="*" element={<></>} />
      </Routes>
    </Suspense>
  )
}
