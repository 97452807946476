import { Box } from '@mui/material'
import { FunctionComponent } from 'react'
import { Entry } from 'contentful'

export interface BlogImageProps {
  s3?: Entry<{ filename: string; s3Url: string }>
  asset?: string
  height?: string
  width?: string
  rounded?: boolean
  roundedSmall?: boolean
  style?: React.CSSProperties
}

/**
 * Renders a blog image with optional rounded corners.
 *
 * @param {Object} props - The properties for the blog image.
 * @param {boolean} props.rounded - Whether the image should have rounded corners.
 * @param {boolean} props.roundedSmall - Whether the image should have small rounded corners.
 * @param {string} props.height - The height of the image. Defaults to '60px'.
 * @param {string} props.width - The width of the image. Defaults to '60px'.
 * @param {string} props.asset - The URL of the image asset.
 * @return {JSX.Element} The rendered blog image.
 */
export const BlogImage: FunctionComponent<BlogImageProps> = ({
  rounded,
  roundedSmall,
  height: propHeight,
  width: propWidth,
  asset,
  style
}) => {
  const height = propHeight || '60px'
  const width = propWidth || '60px'
  const borderRadius = rounded ? '20px' : roundedSmall ? '5px' : '0'

  // Encode the asset URL

  const encodedAsset = asset ? encodeURI(asset.trim()) : ''

  const defaultStyle = {
    backgroundImage: `url("${encodedAsset}")`,
    backgroundSize: 'contain', // Use 'contain' to ensure the image scales correctly
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginLeft: '5px',
    borderRadius
  }

  return (
    <Box
      component="span"
      position="relative"
      height={height}
      width={width}
      display="block"
      style={style ? { ...defaultStyle, ...style } : defaultStyle}
    />
  )
}

export default BlogImage
