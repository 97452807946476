import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../components/Router/ProtectedRoute'
import { RadarCriteriaProvider } from '../contexts/Search/RadarCriteriaProvider'
import { RadarResultsProvider } from '../contexts/Search/RadarResultsProvider'
import { RadarQueryProvider } from '../contexts/Search/RadarQueryProvider'
import React from 'react'

// Lazy load the Search page
const Search = React.lazy(() => import('../pages/Search/Search'))

export const SearchContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading Search...</div>}>
      <Routes>
        <Route
          path={'/search'}
          element={
            <ProtectedRoute>
              <RadarQueryProvider>
                <RadarCriteriaProvider>
                  <RadarResultsProvider>
                    <Search />
                  </RadarResultsProvider>
                </RadarCriteriaProvider>
              </RadarQueryProvider>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<></>} />
      </Routes>
    </Suspense>
  )
}
