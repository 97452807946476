// LibraryCriteriaProvider.tsx
import React from 'react'
import { CriteriaProvider } from './Criteria' // Import the base context

type LibraryCriteriaProviderProps = {
  children?: React.ReactNode
}
export const LibraryCriteriaProvider: React.FC<LibraryCriteriaProviderProps> = ({ children }) => {
  return <CriteriaProvider criteriaType="LIBRARY">{children}</CriteriaProvider>
}
