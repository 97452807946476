import { Box } from '@mui/material'
import { Theme, alpha } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Alert } from '@mui/material'
import { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        lineHeight: 1.5,
        fontSize: 12,
        color: theme.palette.error.dark,
        border: '1px solid ' + theme.palette.error.dark,
        backgroundColor: alpha(theme.palette.error.dark, 0.05),
        paddingTop: 3,
        paddingBottom: 1
      }
    }
  })
)

type ErrorBoxProps = {
  message?: string
  children?: React.ReactNode
}

export const ErrorBox: FunctionComponent<ErrorBoxProps> = (props) => {
  const classes = useStyles()

  return (
    <Box marginY={2} className={classes.root}>
      <Alert severity={'warning'} color={'error'}>
        {props.message && props.message}

        {props.children}
      </Alert>
    </Box>
  )
}
export default ErrorBox
