import { createContext, FunctionComponent, ReactNode, useContext, useReducer } from 'react'
import { Industry, Cycle } from '../../types/Industries'
import { Questions } from '../../declarations/Question'
import { Segments } from '../../types/Segments'
import { Criterion, JourneyCriterion, ProviderCriterion } from '../../declarations/Criterion'

/**
 * Discriminator type for identifying the type of search criteria.
 */
type CriteriaType = 'RADAR' | 'LIBRARY'

/**
 * A type helper for Actions.
 */
type SetCriterionPayload<T> = {
  value: T
}

/**
 * Types for Actions that are used within the reducer functions.
 */
type SetInitialCriteria = { type: 'SET_INITIAL_CRITERIA_STATE'; payload: SetCriterionPayload<Criteria> }
type SetIndustryCriteria = { type: 'SET_INDUSTRY_CRITERIA_STATE'; payload: SetCriterionPayload<Industry[]> }
type SetLoadingCriteria = { type: 'SET_LOADING_CRITERIA_STATE'; payload: SetCriterionPayload<boolean> }
type SetQuestionsCriteria = { type: 'SET_QUESTIONS_CRITERIA_STATE'; payload: SetCriterionPayload<Questions> }

/**
 * A Union for the Actions.
 */
type Action = SetInitialCriteria | SetIndustryCriteria | SetLoadingCriteria | SetQuestionsCriteria

/**
 * Dispatch type helper.
 */
type Dispatch = (action: Action) => void

/**
 * The Criteria state type.
 */
export type Criteria = {
  cycles: Cycle[]
  markets: Criterion[]
  providers: ProviderCriterion[]
  segments: Criterion[]
  journeys: JourneyCriterion[]
  channels: Criterion[]
  audience: Record<
    string,
    {
      id: number
      name: string
      segment: Segments
      __typename: string
    }[]
  >
  questions: Questions
}

/**
 * The State type.
 */
type State = {
  industries: Industry[]
  loading: boolean
  criteriaType: CriteriaType // New property to track the type of criteria
} & Criteria

/**
 * Initial state based on the criteria type.
 */
const getInitialState = (criteriaType: CriteriaType): State => ({
  industries: [],
  loading: true,
  criteriaType,
  cycles: [],
  markets: [],
  providers: [],
  segments: [],
  journeys: [],
  channels: [],
  audience: {},
  questions: {
    questions: [],
    journeys: []
  }
})

type CriteriaContextType = { state: State; dispatch: Dispatch } | undefined
const CriteriaContext = createContext<CriteriaContextType>(undefined)
CriteriaContext.displayName = 'CriteriaContext'

/*
 * Filter journeys based on the state and criteria type.
 */
const filterJourneys = (state: State): State => {
  const validJourneys = new Set(state.questions.journeys.map((x) => x.journeyId))
  const isValidJourney = (j: { id: number }) => validJourneys.has(j.id)
  return {
    ...state,
    journeys: state.journeys.filter(isValidJourney)
  }
}

/**
 * Used for setting the initial criteria to be used.
 */
const setInitialCriteria = (state: State, action: SetInitialCriteria): State => {
  return filterJourneys({
    ...state,
    ...action.payload.value
  })
}

/**
 * Other state management functions remain the same but add criteriaType where necessary.
 */

const setIndustryCriteria = (state: State, action: SetIndustryCriteria): State => {
  return {
    ...state,
    industries: action.payload.value
  }
}

const setLoadingCriteria = (state: State, action: SetLoadingCriteria): State => {
  return {
    ...state,
    loading: action.payload.value
  }
}

const setQuestionCriteria = (state: State, action: SetQuestionsCriteria): State => {
  return filterJourneys({
    ...state,
    questions: action.payload.value
  })
}

/**
 * A reducer for cycling through dispatched events and call their corresponding function.
 *
 * @param {State} state
 * @param {Action} action
 *
 * @throws Error
 */
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_INITIAL_CRITERIA_STATE':
      return setInitialCriteria(state, action)
    case 'SET_INDUSTRY_CRITERIA_STATE':
      return setIndustryCriteria(state, action)
    case 'SET_LOADING_CRITERIA_STATE':
      return setLoadingCriteria(state, action)
    case 'SET_QUESTIONS_CRITERIA_STATE':
      return setQuestionCriteria(state, action)
    default:
      throw new Error(`Unhandled action type in 'CriteriaContext'`)
  }
}

type CriteriaProviderProps = {
  children?: ReactNode
  criteriaType: CriteriaType
}

/**
 * The Criteria Provider component, including in the app bootstrap process.
 */
export const CriteriaProvider: FunctionComponent<CriteriaProviderProps> = ({ children, criteriaType }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(criteriaType))

  return <CriteriaContext.Provider value={{ state, dispatch }}>{children}</CriteriaContext.Provider>
}

/**
 * A hook used for accessing the criteria in components.
 */
export const useCriteria = () => {
  const context = useContext(CriteriaContext)

  if (context === undefined) {
    throw new Error('useCriteria must be used within a CriteriaProvider')
  }

  return context
}
