import { useQuery } from '@apollo/client'
import { Box, Container, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import DashboardBlogList from '../../components/DashboardBlogList'
import { LatestUpdates, Resources } from '../../components/DashboardWidgets'
import { Page } from '../../components/Layout/Page'
import { useIdentityContext } from '../../contexts/IdentityContext'
import { AmplifyUser, useAmplify } from '../../hooks/amplify'
import { GetSearchCriteriaData } from '../../services/api/get-search-criteria'
import { ALL_SEARCH_CRITERIA } from '../../support/graphql/queries'

const Dashboard: FunctionComponent = () => {
  const { currentUser } = useAmplify()
  const [name, setName] = useState('')
  const [userId, setUserId] = useState('')
  const identityContext = useIdentityContext()

  const { loading, data, error } = useQuery(ALL_SEARCH_CRITERIA)

  const getName = useCallback(async () => {
    const user = (await currentUser()) as AmplifyUser

    setUserId(user.getUsername())
    setName(user.attributes.name)

    /**
     * Capture user details for sentry
     */
    if (user && import.meta.env.REACT_APP_DEBUGGING === 'true') {
      Sentry.setUser({
        email: user.attributes.email,
        name: user.attributes.name
      })
    }
  }, [currentUser])

  useEffect(() => {
    getName()
  }, [getName])

  return (
    <Page>
      <Container>
        <Box marginTop={4} marginBottom={2}>
          <Typography variant="h1" style={{ fontSize: '24px', letterSpacing: '-.02em', lineHeight: '1.25' }}>
            Welcome {name}
          </Typography>
        </Box>
        <Box marginBottom={4}>
          <Divider />
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <div>Something went wrong!</div>
            ) : (
              <>
                <Box marginY={4}>
                  <Box marginTop={2}>
                    <AnnouncementBlock />
                  </Box>
                </Box>
                <Grid container spacing={4} style={{ paddingBottom: '2rem' }}>
                  <Grid item xs={6}>
                    <Resources />
                  </Grid>
                  {identityContext.allowRadar === 'true' && (
                    <Grid item xs={6}>
                      <LatestUpdates userId={userId} searchCriteria={data as GetSearchCriteriaData} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant={'h3'} style={{ marginBottom: '18px' }}>
                      Latest Insights
                    </Typography>
                    <DashboardBlogList />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Container>
    </Page>
  )
}
/**
 * Renders a component displaying the latest updates and announcements from the boss.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AnnouncementBlock = () => {
  return (
    <Paper>
      <Box paddingX={3} paddingY={2}>
        <Typography variant={'h1'} style={{ marginBottom: '1rem' }}>
          📣 What&apos;s New
        </Typography>
        <Divider />
        <Grid direction="column" spacing={2}>
          <Box my={'1em'}>
            <Typography variant={'h3'}>August 2024</Typography>
          </Box>
          <Box>
            <Typography variant="h4">📊 Dashboard Enhancements</Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  Direct Report Downloads: You can now download the latest reports directly from the dashboard.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Bandwidth Alert: The system will warn you if the download is large, helping you manage your data usage
                  if you’re on a limited bandwidth connection.
                </Typography>
              </li>
            </ul>
            <Typography>
              Have something you would like to see? Contact us at{' '}
              <a href="mailto:swiftassist@iskyresearch.com">swiftassist@iskyresearch.com</a> with your idea.
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Paper>
  )
}

const Loading = () => {
  return (
    <>
      <Box marginY={4}>
        <Box display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'}>
          <Typography variant={'h3'}>Start a new search</Typography>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'156px'} height={44} />
        </Box>
        <Box marginTop={2}>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={74} />
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={279} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={279} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={364} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={364} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h3'} style={{ marginBottom: '18px' }}>
            Latest blog posts
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={382} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={382} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton style={{ borderRadius: '6px' }} variant="rectangular" width={'100%'} height={382} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
