import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../components/Router/ProtectedRoute'
import React from 'react'

// Lazy load the Blog components
const BlogIndex = React.lazy(() => import('../pages/Blog/Index').then((module) => ({ default: module.Index })))
const BlogSearch = React.lazy(() => import('../pages/Blog/Search').then((module) => ({ default: module.Search })))
const BlogShow = React.lazy(() => import('../pages/Blog/Show').then((module) => ({ default: module.Show })))

export const BlogContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={'/insights'}
          element={
            <ProtectedRoute>
              <BlogIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/insights/search'}
          element={
            <ProtectedRoute>
              <BlogSearch />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/insights/:id'}
          element={
            <ProtectedRoute>
              <BlogShow />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<></>} />

        {/* REDIRECT TO INSIGHTS AS IT IS THE NEW NAMING */}
      </Routes>
    </Suspense>
  )
}
