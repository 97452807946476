/**
 * @file LibraryQueryProvider.tsx
 * @description This file provides a context wrapper specifically for managing the query state of the Library section.
 * It is designed to separate the Library query state from other parts of the application, such as the Radar section,
 * allowing for distinct management of state across different domains.
 */

import { FunctionComponent, ReactNode } from 'react'
import { QueryProvider } from './Query' // Import the base context

/**
 * @typedef {Object} LibraryQueryProviderProps
 * @property {ReactNode} children - The child components to be wrapped by the LibraryQueryProvider.
 */
type LibraryQueryProviderProps = { children: ReactNode }
/**
 * LibraryQueryProvider is a wrapper component that provides a context for managing query state
 * specific to the Library section of the application. By passing the `queryType` as "LIBRARY",
 * this ensures that the query state is isolated from other sections like Radar.
 *
 * @component
 * @param {LibraryQueryProviderProps} props - The props for the LibraryQueryProvider component.
 * @returns {JSX.Element} The component that wraps its children in a Library-specific query context.
 */
export const LibraryQueryProvider: FunctionComponent<LibraryQueryProviderProps> = ({ children }) => {
  return <QueryProvider queryType="LIBRARY">{children}</QueryProvider>
}
