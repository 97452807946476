import { Container } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header/Header'
import { Footer } from './Footer'

type LayoutProps = {
  children: React.ReactElement
}

const LayoutContainer = styled(Container)`
  && {
    background: #f6f8fa;
    height: 100%;
    position: relative;
  }
`

const Layout = (props: LayoutProps) => {
  return (
    <LayoutContainer maxWidth={false} disableGutters={true}>
      <Header />
      {props.children}
      <Footer />
    </LayoutContainer>
  )
}

export default Layout
