import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Box, Container } from '@mui/material'
import HeaderLogo from '../Header/HeaderLogo/HeaderLogo'

const LayoutContainer = styled(Container)`
  && {
    background: #f6f8fa;
    height: 100%;
    position: relative;
  }
`

type ErrorProps = PropsWithChildren<{}>

export const Error = (props: ErrorProps) => {
  return (
    <LayoutContainer maxWidth={false} disableGutters={true}>
      <Container maxWidth={false} disableGutters={true} style={{ minHeight: 'calc(100vh - 132px)' }}>
        <Box display={'flex'} bgcolor={'#001433'} maxWidth={'lg'} minHeight={'64px'}>
          <Box marginX={'auto'}>
            <HeaderLogo type={'default'} />
          </Box>
        </Box>

        <Box maxWidth={'500px'} marginX={'auto'} marginTop={'50px'}>
          {props.children}
        </Box>
      </Container>
    </LayoutContainer>
  )
}
