import { Auth } from 'aws-amplify'
import { AmplifySuccess, AmplifyUnknownError } from '../../types/Amplify'

type SignOutResult = AmplifySuccess | AmplifyUnknownError

/**
 * Handles the signing out of the user via the amplify sdk.
 *
 * @returns {Promise<SignOutResult>}
 */
export const signOut = async (): Promise<SignOutResult> => {
  try {
    await Auth.signOut()

    return { type: 'success' }
  } catch (caught) {
    return { type: 'unknown-error', exception: caught }
  }
}
