import { Container, Grid, useTheme } from '@mui/material'
import { LogoEyeIcon } from '../Common/icons'
import { useEffect, useState } from 'react'

export const Footer = () => {
  const theme = useTheme()
  const [commitHash, setCommitHash] = useState<string | null>(null)

  useEffect(() => {
    // Dynamically fetch the commit hash JSON file if it exists
    fetch('/commit-hash.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Commit hash file not found')
        }
        return response.json()
      })
      .then((data) => {
        setCommitHash(data.commitHash)
      })
      .catch((error) => {
        console.warn('Could not load commit hash:', error)
      })
  }, [])
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      style={{
        backgroundColor: theme.palette.primary.main,
        color: '#98A5B3',
        fontSize: '14px',
        paddingTop: '14px',
        paddingBottom: '16px'
      }}
    >
      <Container maxWidth={'lg'}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid container item xs={12}>
            <LogoEyeIcon styles={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '8px' }} /> © 2018-
            {new Date().getFullYear()} iSky Research | {APP_VERSION}-{commitHash}
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
