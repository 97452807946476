import { createContext, FunctionComponent, ReactNode, useContext, useReducer } from 'react'
import { Cycle, Industry } from '../../types/Industries'
import { Segments } from '../../types/Segments'
import { XQValues } from '../../types/XQScore'

type QueryTypeEnum = 'RADAR' | 'LIBRARY'

type SetQueryPayload<T> = {
  value: T
}

type SetInitialQuery = { type: 'SET_INITIAL_QUERY_STATE'; payload: SetQueryPayload<Query> }
type SetInitialState = { type: 'SET_INITIAL_STATE'; payload: SetQueryPayload<State> }
type SetMarketQuery = { type: 'SET_MARKET_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetProviderQuery = { type: 'SET_PROVIDER_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetSegmentQuery = { type: 'SET_SEGMENT_QUERY_STATE'; payload: SetQueryPayload<Segments[]> }
type SetChannelQuery = { type: 'SET_CHANNEL_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetAudienceQuery = { type: 'SET_AUDIENCE_QUERY_STATE'; payload: SetQueryPayload<Record<string, number[]>> }
type SetQuestionQuery = {
  type: 'SET_QUESTION_QUERY_STATE'
  payload: SetQueryPayload<{ type?: string; questionIds?: number[] }>
}
type SetSortQuery = {
  type: 'SET_SORT_QUERY_STATE'
  payload: SetQueryPayload<{ label: string; field: string; direction: string; friction_question?: number }>
}
type SetPaginationQuery = {
  type: 'SET_PAGINATION_QUERY_STATE'
  payload: SetQueryPayload<{ page: number; offset: number; limit: number }>
}
type SetSelectedIndustry = { type: 'SET_SELECTED_INDUSTRY'; payload: SetQueryPayload<Industry> }
type SetSelectedCycle = { type: 'SET_SELECTED_CYCLE'; payload: SetQueryPayload<Cycle> }
type SetXQScoreQuery = { type: 'SET_XQ_SCORE_QUERY_STATE'; payload: SetQueryPayload<XQValues[]> }
type SetJourneyQuery = { type: 'SET_JOURNEY_QUERY_STATE'; payload: SetQueryPayload<number[]> }
type SetFrictionScoreQuery = { type: 'SET_FRICTION_SCORE_QUERY_STATE'; payload: SetQueryPayload<[number, number]> }
type SetDateRangeQuery = {
  type: 'SET_DATE_RANGE_QUERY_STATE'
  payload: SetQueryPayload<[string | null, string | null]>
}

type Action =
  | SetInitialQuery
  | SetMarketQuery
  | SetProviderQuery
  | SetSegmentQuery
  | SetChannelQuery
  | SetAudienceQuery
  | SetQuestionQuery
  | SetSortQuery
  | SetPaginationQuery
  | SetSelectedIndustry
  | SetSelectedCycle
  | SetXQScoreQuery
  | SetJourneyQuery
  | SetFrictionScoreQuery
  | SetDateRangeQuery
  | SetInitialState

type Dispatch = (action: Action) => void

export type Query = {
  markets: number[]
  providers: number[]
  segments: Segments[]
  channels: number[]
  audience: Record<string, number[]>
  xqScore: XQValues[]
  questions: {
    type: string
    questionIds: number[]
  }
  sort: {
    label: string
    field: string
    direction: string
    friction_question?: number
    friction_question_text?: string
  }
  pagination: {
    page: number
    offset: number
    limit: number
  }
}

export type State = {
  queryType: QueryTypeEnum
  selectedIndustry: Industry | Industry[] // Single or multiple industries depending on context
  selectedCycle: Cycle
  journey: number[]
  frictionScore: [number, number]
  dateRange: [string | null, string | null]
} & Query

type QueryContextType = { state: State; dispatch: Dispatch } | undefined
const QueryContext = createContext<QueryContextType>(undefined)
QueryContext.displayName = 'QueryContext'

const setInitialQuery = (state: State, action: SetInitialQuery): State => {
  return {
    ...state,
    ...action.payload.value
  }
}

const setInitialState = (state: State, action: SetInitialState): State => {
  return {
    ...state,
    ...action.payload.value
  }
}

const setMarketQuery = (state: State, action: SetMarketQuery): State => {
  return {
    ...state,
    markets: action.payload.value
  }
}

const setProviderQuery = (state: State, action: SetProviderQuery): State => {
  return {
    ...state,
    providers: action.payload.value
  }
}

const setSegmentQuery = (state: State, action: SetSegmentQuery): State => {
  return {
    ...state,
    segments: action.payload.value
  }
}

const setChannelQuery = (state: State, action: SetChannelQuery): State => {
  return {
    ...state,
    channels: action.payload.value
  }
}

const setAudienceQuery = (state: State, action: SetAudienceQuery): State => {
  return {
    ...state,
    audience: action.payload.value
  }
}

const setQuestionQuery = (state: State, action: SetQuestionQuery): State => {
  return {
    ...state,
    questions: {
      ...state.questions,
      ...action.payload.value
    }
  }
}

const setSortQuery = (state: State, action: SetSortQuery): State => {
  return {
    ...state,
    sort: action.payload.value
  }
}

const setPaginationQuery = (state: State, action: SetPaginationQuery): State => {
  return {
    ...state,
    pagination: action.payload.value
  }
}

const setSelectedIndustry = (state: State, action: SetSelectedIndustry): State => {
  if (state.queryType === 'RADAR') {
    // Single selection for RADAR
    return {
      ...state,
      selectedIndustry: action.payload.value
    }
  } else {
    // Multi-selection for LIBRARY
    const existingIndustries = Array.isArray(state.selectedIndustry) ? state.selectedIndustry : []
    const industryExists = existingIndustries.find((ind) => ind.industryId === action.payload.value.industryId)

    return {
      ...state,
      selectedIndustry: industryExists
        ? existingIndustries.filter((ind) => ind.industryId !== action.payload.value.industryId) // Remove if already selected
        : [...existingIndustries, action.payload.value] // Add if not selected
    }
  }
}

const setSelectedCycle = (state: State, action: SetSelectedCycle): State => {
  return {
    ...state,
    selectedCycle: action.payload.value
  }
}

const setXQScoreQuery = (state: State, action: SetXQScoreQuery): State => {
  return {
    ...state,
    xqScore: action.payload.value
  }
}

const setJourneyQuery = (state: State, action: SetJourneyQuery): State => {
  return {
    ...state,
    journey: action.payload.value
  }
}

const setFrictionScoreQuery = (state: State, action: SetFrictionScoreQuery): State => {
  return {
    ...state,
    frictionScore: action.payload.value
  }
}

const setDateRangeQuery = (state: State, action: SetDateRangeQuery): State => {
  return {
    ...state,
    dateRange: action.payload.value
  }
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INITIAL_QUERY_STATE':
      return setInitialQuery(state, action)
    case 'SET_INITIAL_STATE':
      return setInitialState(state, action)
    case 'SET_MARKET_QUERY_STATE':
      return setMarketQuery(state, action)
    case 'SET_PROVIDER_QUERY_STATE':
      return setProviderQuery(state, action)
    case 'SET_SEGMENT_QUERY_STATE':
      return setSegmentQuery(state, action)
    case 'SET_CHANNEL_QUERY_STATE':
      return setChannelQuery(state, action)
    case 'SET_AUDIENCE_QUERY_STATE':
      return setAudienceQuery(state, action)
    case 'SET_QUESTION_QUERY_STATE':
      return setQuestionQuery(state, action)
    case 'SET_SORT_QUERY_STATE':
      return setSortQuery(state, action)
    case 'SET_PAGINATION_QUERY_STATE':
      return setPaginationQuery(state, action)
    case 'SET_SELECTED_INDUSTRY':
      return setSelectedIndustry(state, action)
    case 'SET_SELECTED_CYCLE':
      return setSelectedCycle(state, action)
    case 'SET_XQ_SCORE_QUERY_STATE':
      return setXQScoreQuery(state, action)
    case 'SET_JOURNEY_QUERY_STATE':
      return setJourneyQuery(state, action)
    case 'SET_FRICTION_SCORE_QUERY_STATE':
      return setFrictionScoreQuery(state, action)
    case 'SET_DATE_RANGE_QUERY_STATE':
      return setDateRangeQuery(state, action)
    default:
      throw new Error(`Unhandled action type in 'QueryContext'`)
  }
}

type QueryProviderProps = {
  children?: ReactNode
  queryType: QueryTypeEnum
}

export const QueryProvider: FunctionComponent<QueryProviderProps> = ({ children, queryType }) => {
  const [state, dispatch] = useReducer(reducer, {
    queryType, // Include queryType in state
    markets: [],
    providers: [],
    segments: [],
    channels: [],
    audience: {},
    questions: {
      type: 'OR',
      questionIds: []
    },
    sort: { label: 'VoC Score (high-low)', field: 'CX_SCORE', direction: 'DESC' },
    pagination: {
      page: 1,
      offset: 0,
      limit: 12
    },
    xqScore: [],
    selectedIndustry: queryType === 'RADAR' ? ({} as Industry) : ([] as Industry[]), // Single for RADAR, Array for LIBRARY
    selectedCycle: {} as Cycle,
    journey: [],
    frictionScore: [0, 100],
    dateRange: [null, null]
  })

  return <QueryContext.Provider value={{ state, dispatch }}>{children}</QueryContext.Provider>
}

export const useSearchQuery = () => {
  const context = useContext(QueryContext)
  if (context === undefined) {
    throw new Error('useSearchQuery must be used within a QueryProvider')
  }
  return context
}
