import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorBox } from '../../components/Auth/ErrorBox'
import { Page } from '../../components/Layout/Page'
import { UNEXPECTED_ERROR_MESSAGE } from '../../helpers/messages'
import { Auth } from 'aws-amplify'
import { signOut } from '../../support/amplify/sign-out'
import { useIdentityContext } from '../../contexts/IdentityContext'

export const REVALIDATION_INTERVAL = 90

// returns the number of calendar days between d1 and d2 assuming
// the observer, d1, and d2 are all in UTC
export const utcDaysDifference = (d1: Date, d2: Date) =>
  // note that although UTC days normally have 86400 seconds because they aren't
  // susceptible to DST, we still round in case of leap seconds
  Math.round(
    (Date.UTC(d2.getUTCFullYear(), d2.getUTCMonth(), d2.getUTCDate()) -
      Date.UTC(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate())) /
      86400000
  )

const LOGIN_EXPIRED_MESSAGE = `Your login has expired, please click "Logout" then log back in and try again.`

export const Revalidate = () => {
  const location = useLocation()

  const [error, setError] = useState<string | undefined>(undefined)
  const [input, setInput] = useState({
    username: location.state?.username || '',
    code: ''
  })
  const { session } = useIdentityContext()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const form = {
      ...input,
      [event.target.name]: event.target.value
    }

    setInput(form)
  }

  const handleRequestCode = async (anotherCode: boolean) => {
    setError('')
    try {
      await Auth.verifyCurrentUserAttribute('email')
      if (anotherCode) {
        setError(
          'A new code has been requested and should appear in your email shortly. Note you can only use the last code requested.'
        )
      }
    } catch (e: any) {
      if (e === 'No current user') {
        setError(LOGIN_EXPIRED_MESSAGE)
      } else {
        setError(e.message || UNEXPECTED_ERROR_MESSAGE)
      }
    }
  }

  const handleLogout = async () => {
    setError('')
    await signOut()
    window.location.href = '/login'
  }

  useEffect(() => {
    handleRequestCode(false)
  }, [])

  const handleSubmit = async () => {
    setError('')
    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit('email', input.code)
      console.log('verification result', result)
      await Auth.updateUserAttributes(session, {
        'custom:LastRevalidationDate': new Date().toISOString()
      })
      window.location.href = '/'
    } catch (e: any) {
      console.log(e)
      if (e === 'No current user') {
        setError(LOGIN_EXPIRED_MESSAGE)
      } else {
        setError(e.message || 'Invalid Code')
      }
    }
  }

  return (
    <Page>
      <Container>
        <Container maxWidth={'sm'}>
          <Box display={'flex'} flexDirection={'column'} maxWidth={'md'}>
            <Box marginBottom={4}>
              <Paper>
                <Box padding={4}>
                  <Typography variant={'h1'}>Confirm Email</Typography>
                  {error && <ErrorBox message={error} />}
                  <Box marginY={2}>
                    <Typography variant={'body1'}>
                      <p>Periodically we will ask you to reconfirm access to your registered email address.</p>
                      <p>You should have received a verification code in your {session?.attributes.email} inbox.</p>
                      <p>
                        Please enter the verification code below. If you encounter any difficulties please request
                        another code or{' '}
                        <a href={'https://iskyresearch.com/#contactArea'} style={{ color: '#001433' }}>
                          contact us
                        </a>
                      </p>
                    </Typography>
                  </Box>
                  <Box marginY={2}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: '#647386',
                        fontWeight: 600
                      }}
                      variant={'subtitle2'}
                    >
                      Verification Code
                    </Typography>
                    <TextField
                      style={{
                        marginTop: '4px'
                      }}
                      onChange={handleChange}
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      required={true}
                      type={'text'}
                      name={'code'}
                      id="code"
                      aria-describedby="your verification code"
                      autoComplete={'off'}
                    />
                  </Box>
                  <Box marginY={2}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Button color={'secondary'} variant={'contained'} onClick={handleSubmit}>
                        Confirm Account
                      </Button>
                      <Button color={'secondary'} variant={'text'} onClick={() => handleRequestCode(true)}>
                        Request Another Code
                      </Button>
                      <Button color={'secondary'} variant={'text'} onClick={handleLogout}>
                        Logout
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Container>
    </Page>
  )
}
export default Revalidate
