import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'

export type AmplifyUser = CognitoUser & {
  attributes: {
    [key: string]: string
    ['custom:ApprovalStatus']: 'pending' | 'approved'
  }
}

/**
 * Fetches the current user that's logged in from Amplify.
 *
 * @returns {Promise<AmplifyUser | null>}
 */
const currentUser = async (): Promise<AmplifyUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch (error) {}

  return null
}

/**
 * Fetches the JWT access token of the current logged in user.
 *
 * @returns {Promise<string | null>}
 */
export const jwtToken = async (): Promise<string | null> => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken()
  } catch (error) {}

  return null
}

/**
 * Fetches the user group claim from the JWT access token.
 *
 * @returns {Promise<string | null>}
 */
export const userGroup = async (): Promise<string | null> => {
  try {
    const { domain } = (await Auth.currentSession()).getIdToken().payload

    if (domain) return domain

    return null
  } catch (error) {}

  return null
}

/**
 * Fetches the Brand claim from the JWT access token.
 *
 * @returns {Promise<string | null>}
 */
export const userBrand = async (): Promise<string | null> => {
  try {
    const { brand } = (await Auth.currentSession()).getIdToken().payload

    if (brand) return brand

    return null
  } catch (error) {}

  return null
}

/**
 * Fetches the revalidate_email claim from the JWT access token.
 *
 * @returns {Promise<string | null>}
 */
export const revalidate_email = async (): Promise<string | null> => {
  try {
    const { revalidate_email } = (await Auth.currentSession()).getIdToken().payload

    if (revalidate_email) return revalidate_email

    return null
  } catch (error) {}

  return null
}

/**
 * Fetches the allow_radar claim from the JWT access token.
 *
 * @returns {Promise<string | null>}
 */
export const allow_radar = async (): Promise<string | null> => {
  try {
    const { allow_radar } = (await Auth.currentSession()).getIdToken().payload

    if (allow_radar) return allow_radar

    return null
  } catch (error) {}

  return null
}

/**
 * Fetches the allow_library claim from the JWT access token.
 *
 * @returns {Promise<string | null>}
 */
export const allow_library = async (): Promise<string | null> => {
  try {
    const { allow_library } = (await Auth.currentSession()).getIdToken().payload

    if (allow_library) return allow_library

    return null
  } catch (error) {}

  return null
}

/**
 * Amplify hook for usage in components.
 */
export const useAmplify = () => {
  return {
    userGroup,
    userBrand,
    jwtToken,
    currentUser,
    revalidate_email,
    allow_radar,
    allow_library
  }
}
