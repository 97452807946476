import { Box, useTheme } from '@mui/material'
import { AccountCircleOutlined, HelpOutline, PowerSettingsNew } from '@mui/icons-material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { IdentityContextType, useIdentityContext } from '../../../contexts/IdentityContext'
import { signOut } from '../../../support/amplify/sign-out'
import { useFlag } from '@openfeature/react-sdk'
import { FeatureFlag } from '../../../helpers/featureFlags'

const Item = styled(NavLink)`
  && {
    // color: white !important;
    text-transform: capitalize;
    text-decoration: none;
    font-family: acumin-pro-wide, sans-serif !important;
    transition: border 0.12s ease-in-out;
    position: relative;
    display: block;
    box-sizing: border-box;
    &:after {
      content: '';
      position: absolute;
      display: block;
      box-sizing: border-box;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 6px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: background-color 0.15s ease;
    }

    &:hover {
      &:after {
        background-color: white;
      }
    }
    &.active {
      &:after {
        background-color: rgba(229, 18, 110, 1);
      }
    }
  }
`

interface IMenuItem {
  isDropdown?: boolean
  link: string
  label?: any
  /*
   * identityContextFeatureFlag probably requires a bit of explanation: The identityContext
   * contains a number of flags which have a string value "true" or "false", setting the
   * identityContextFeatureFlag on a menu item means that item will only be shown if the
   * corresponding field in the identityContext is "true"
   */
  identityContextFeatureFlag?: keyof IdentityContextType
}

const MenuItem = ({ menuItem }: { menuItem: IMenuItem }) => {
  const theme = useTheme()
  const identityContext = useIdentityContext()
  if (menuItem.identityContextFeatureFlag && identityContext[menuItem.identityContextFeatureFlag] !== 'true') {
    return null
  }
  return (
    <Item style={{ padding: '20px 10px', color: theme.palette.navigation.dark }} to={menuItem.link}>
      {menuItem.label}
    </Item>
  )
}

const LogoutMenuItem = () => {
  const theme = useTheme()
  const { setIsLoggedIn } = useIdentityContext()

  const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      await signOut()
      setIsLoggedIn(false)

      window.location.href = '/login'
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Item style={{ padding: '20px 10px', color: theme.palette.navigation.dark }} onClick={handleLogout} to={'logout'}>
      <PowerSettingsNew />
    </Item>
  )
}

const menu: IMenuItem[] = [
  {
    isDropdown: true,
    link: '/search',
    label: 'Radar',
    identityContextFeatureFlag: 'allowRadar'
  },
  {
    link: '/resources',
    label: 'Reports'
  },
  {
    link: '/insights',
    label: 'Insights'
  },
  {
    link: '/library',
    label: 'Library'
  },
  {
    link: '/help' /*@TODO*/,
    label: <HelpOutline />
  },
  {
    link: '/my-account',
    label: <AccountCircleOutlined />
  }
]

export interface MenuProps {}

export const Menu: React.FC<MenuProps> = () => {
  /**
   *  FEATURE FLAG - START
   */
  const { value: showLibrary } = useFlag(FeatureFlag.ShowLibrary, false)
  const flaggedMenu = showLibrary ? menu : menu.filter((item) => item.link !== '/library')
  /**
   *  FEATURE FLAG - END
   */

  return (
    <Box display="flex" justifyContent="space-between">
      {flaggedMenu.map((menuItem) => (
        <MenuItem key={menuItem.link} menuItem={menuItem} />
      ))}
      <LogoutMenuItem />
    </Box>
  )
}

export default Menu
