import { FunctionComponent, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from '../components/Router/PrivateRoute'
import React from 'react'

// Lazy load the components
const MyAccount = React.lazy(() => import('../pages/Account').then((module) => ({ default: module.MyAccount })))
const Help = React.lazy(() => import('../pages/Help/Index').then((module) => ({ default: module.Index })))

export const AccountContainer: FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={'/my-account'}
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path={'/help'}
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<></>} />
      </Routes>
    </Suspense>
  )
}
