import { Container } from '@mui/material'
import { FunctionComponent } from 'react'

type ContainerProps = {
  children: React.ReactNode
}
export const Page: FunctionComponent<ContainerProps> = (props) => {
  return (
    <Container maxWidth={false} disableGutters={true} style={{ minHeight: 'calc(100vh - 132px)' }}>
      <div>
        <>{props.children}</>
      </div>
    </Container>
  )
}

export default Page
